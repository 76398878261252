import React from "react"
import { Link } from "gatsby"

import { Text, TextType, TextFontSize } from "../components/Text"

import "./page.styles.scss"

const NotFoundPage = () => {
  return (
    <div className={"page"}>
      <Text type={TextType.H3} size={TextFontSize.big}>
        Sorry, this page does not exist
      </Text>
      <Link to={"/"}>Go back to main page</Link>
    </div>
  )
}

export default NotFoundPage
